import { render, staticRenderFns } from "./BrandLogosWidgetCarouselLayout.vue?vue&type=template&id=3110ebf2&scoped=true"
import script from "./BrandLogosWidgetCarouselLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./BrandLogosWidgetCarouselLayout.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BrandLogosWidgetCarouselLayout.vue?vue&type=style&index=0&id=3110ebf2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3110ebf2",
  null
  
)

export default component.exports